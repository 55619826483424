import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

export default function ModalNovoCaixa({ visible, change, refreshCaixas}) {

  const [loaderPage, setloaderPage] = useState(false);
 
//   useEffect(()=>{
    

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   },[]);

  const validationSchema = yup.object({
    nome: yup
      .string('Informe o nome do caixa')
      .required('Nome Obrigatório'),
    
  });

  const formik = useFormik({
    initialValues: {
      nome: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.post('novoCaixa', {
        "nome": values.nome,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshCaixas();
      change(false);

    }
  });

  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo caixa
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="nome"
                        name="nome"
                        label="Nome do caixa"
                        margin="none"
                        type="text"
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                        helperText={formik.touched.nome && formik.errors.nome}
                        inputProps={{
                            maxLength: 155,
                        }}
                    />
                </Grid>
                
          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Adicionar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}