import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonSecondaryOnlyIcon({ type, icon, onClick, component, disabled = false }) {

  return (
    <Button disabled={disabled} component={component} onClick={onClick} id='btn-secondary-only-icon' type={type}>
        {icon}
    </Button>
  );
}