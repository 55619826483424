import React, {useState, useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config

import api from '../../../services/api';
 
export default function DocumentPDFusuarios({ pessoasIds }) {

  const [listagemPessoasExport, setlistagemPessoasExport] = useState([]);

  const getDataExportPessoas = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let pessoas = await api.post('listagemUsuariosSelecionados', {
        'pessoasIds': pessoasIds
        }, 
        {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
        });

        setlistagemPessoasExport(pessoas.data);
  }

  useEffect(()=>{
    getDataExportPessoas();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pessoasIds]);


  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        }
  });

  return (
    <Document>
        <Page size="A4" style={styles.page}>

        <View style={styles.header}>
            <Text>Cadastro de usuários</Text>
        </View>


        <View style={ styles.rowHeader }>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Nome</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>E-mail</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Documento</Text>
            </View>
        </View>

        {listagemPessoasExport.map((v, k) =>{
            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.nome}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.email}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.tipo === 'Física' ? v.cpf : v.cnpj}</Text>
                    </View>
                </View>
            )
        })}
        
        </Page>
    </Document> 
  );
}