import React from 'react';
import './style.css';
import { Link, useHistory } from "react-router-dom";

export default function Submenu({ itens }) {

    let history = useHistory();

  return (
      <div className='box-submenu'>
        <div className='container'>
            {itens?.map((v, k) =>{
                return (
                    <div key={k} className={`item ${v.active}`} onClick={()=>{ history.push(v.rota); }}>
                        <Link to={v.rota}>
                            {v.icon} <p>{v.title}</p>
                        </Link>
                    </div>
                )
            })}
        </div>
      </div>
  );
}