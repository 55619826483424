import React, {useState, useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import moment from 'moment';
import api from '../../services/api';
 
export default function DocumentPDFcaixas({ planosIds }) {

  const [listagemExport, setlistagemExport] = useState([]);

  const getDataExport = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post('listagemplanoContasSelecionados', {
        'planosIds': planosIds
        }, 
        {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
        });

        setlistagemExport(response.data);
  }

  useEffect(()=>{
    getDataExport();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[planosIds]);


  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        }
  });

  return (
    <Document>
        <Page size="A4" style={styles.page}>

        <View style={styles.header}>
            <Text>Plano de contas orçamentárias</Text>
        </View>


        <View style={ styles.rowHeader }>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Data</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Descrição</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Operação</Text>
            </View>
        </View>

        {listagemExport.map((v, k) =>{

            let operacao;

            if(v.operacao === 1){
                operacao = 'Crédito';
            }else if(v.operacao === 2){
                operacao = 'Débito';
            }else if(v.operacao === 3){
                operacao = 'Neutra';
            }

            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{moment(v.data).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.descricao}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{operacao}</Text>
                    </View>
                </View>
            )
        })}
        
        </Page>
    </Document> 
  );
}