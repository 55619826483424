import React, {useEffect, useState} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import moment from 'moment';
import { formataDinheiro } from '../../vendor/formatar';


export default function DocumentPDFfluxo({ startDate,  endDate, dados}) {

  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            marginTop: '10px'
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        },
        rowSaldo:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#ecedee',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
            paddingVertical: '5px'
        },
        txtrowSaldo:{
            fontSize: '11px',
            color: '#000',
            fontWeight: 'bold'
        },
  });
  
  return (
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>

        
        <View style={styles.header}>
            <Text>Fluxo de caixa</Text>
            <Text style={{ fontSize: '10px' }}>De {moment(startDate).format('DD/MM/YYYY')} a {moment(endDate).format('DD/MM/YYYY')}</Text>
        </View>

        <View style={ styles.rowSaldo }>
            <View style={{ display: 'flex', flex: '5' }}>
                <Text style={styles.txtrowSaldo}>SALDO ANTERIOR</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Receitas</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoAnteriorTotalReceitas?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Despesas</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoAnteriorTotalDespesas?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>A Receber</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoAnteriorTotalAReceber?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>A Pagar</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoAnteriorTotalApagar?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Saldo</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoAnteriorTotalSaldo?.total)}</Text>
            </View>
        </View>


        <View style={ styles.rowHeader}>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Operação</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Data</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Conta Orç.</Text>
            </View>
            <View style={{ display: 'flex', flex: '2' }}>
                <Text style={styles.txtrowHeader}>Descrição</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Receitas</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Despesas</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>A Receber</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>A Pagar</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Saldo</Text>
            </View>
        </View>

        {dados?.fluxos.map((v, k) =>{
            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.operacao === 1 ? 'Crédito' : 'Débito'}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{moment(v.data).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.contaOrcamentaria}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '2', paddingRight: '10px' }}>
                        <Text style={styles.txtrow}>{v.descricao}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.receitas)}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.despesas)}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.receber)}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.pagar)}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{formataDinheiro(v.saldo)}</Text>
                    </View>
                </View>
            )
        })}

        <View style={{marginTop: '15px'}}>
        <View style={ styles.rowSaldo }>
            <View style={{ display: 'flex', flex: '5' }}>
                <Text style={styles.txtrowSaldo}>SALDO FINAL</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Receitas</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoFinalTotalReceitas?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Despesas</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoFinalTotalDespesas?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>A Receber</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoFinalTotalAReceber?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>A Pagar</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoFinalTotalApagar?.total)}</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowSaldo}>Saldo</Text>
                <Text style={{ fontSize: '9px' }}>{formataDinheiro(dados?.resumoFinalTotalSaldo?.total)}</Text>
            </View>
        </View>
        </View>
        
        </Page>
    </Document> 
  );
}