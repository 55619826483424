import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonSecondarySlim({ type, icon, txt, onClick, component, disabled = false }) {

  return (
    <Button disabled={disabled} component={component} onClick={onClick} id='btn-secondary-slim' type={type}>
        {icon} {txt}
    </Button>
  );
}