import React, {useState, useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import moment from 'moment';
import api from '../../services/api';
import { formataDinheiro } from '../../vendor/formatar';
 
export default function DocumentPDFlancamentosCaixas({ lancamentosIds }) {

  const [listagemExport, setlistagemExport] = useState([]);

  const getDataExportCaixas = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let lancamentos = await api.post('listagemLancamentosCaixaSelecionados', {
        'lancamentosIds': lancamentosIds
        }, 
        {
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        }
        });

        setlistagemExport(lancamentos.data);
  }

  useEffect(()=>{
    getDataExportCaixas();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[lancamentosIds]);


  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        }
  });

  return (
    <Document>
        <Page size="A4" style={styles.page}>

        <View style={styles.header}>
            <Text>Lançamentos Caixa</Text>
        </View>


        <View style={ styles.rowHeader }>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Data</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Caixa</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Operação</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Valor</Text>
            </View>
            <View style={{ display: 'flex', flex: '3' }}>
                <Text style={styles.txtrowHeader}>Descrição</Text>
            </View>
        </View>

        {listagemExport.map((v, k) =>{

            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{moment(v.data).format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.caixa}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.operacao === 1 ? 'Crédito' : 'Débito'}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{ formataDinheiro(v.valor) }</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '3' }}>
                        <Text style={styles.txtrow}>{v.descricao}</Text>
                    </View>
                </View>
            )
        })}
        
        </Page>
    </Document> 
  );
}