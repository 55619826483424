import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonSquadPrimaryForInput({ icon, onClick, txt, component }) {
    
  return (
    <Button id='button-squad-primary-for-input' type='button' component={component} onClick={onClick}>
      {
        txt ?
        <span>
          {icon} {txt && <p>{txt}</p>}
        </span>

        :

        <>
          {icon}
        </>
      }  
    </Button>
  );
}