import React from 'react';
import './style.css';

export default function InputSearchActions({ placeholder, onChange }) {
  
  let IconSearch = props =>(
    <svg id="search" xmlns="http://www.w3.org/2000/svg" width="22.975" height="22.967" viewBox="0 0 22.975 22.967">
        <g id="search_1_">
        <path id="Caminho_58" data-name="Caminho 58" d="M68.242,20.18A10.048,10.048,0,1,1,78.29,10.132,10.048,10.048,0,0,1,68.242,20.18Zm0-17.225a7.177,7.177,0,1,0,7.177,7.177A7.177,7.177,0,0,0,68.242,2.955Z" transform="translate(-55.315 -0.084)" fill="#9c9c9c"/>
        <path id="Caminho_59" data-name="Caminho 59" d="M1.444,305.528a1.435,1.435,0,0,1-1.027-2.446l.008-.008,5.4-5.4a1.467,1.467,0,1,1,2.038,2.11l-5.4,5.325a1.435,1.435,0,0,1-1.019.416Z" transform="translate(0 -282.561)" fill="#9c9c9c"/>
        </g>
    </svg>
  ); 

  return (
    <div className='box-input-search-acs'>
        <div className='box-input'>
            <input type="search" placeholder={placeholder} onChange={onChange} />
        </div>
        <div className='box-icon'>
            <IconSearch />
        </div>
    </div>
  );
}