import React from 'react';
import ReactDOM from 'react-dom';
import Rotas from './routes/Rotas';
// import { ThemeProvider } from '@mui/material/styles';
// import tema from './theme';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={tema}> */}
      <Rotas />
    {/* </ThemeProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);