import React, {useState, useRef } from 'react';
import './style.css';

import api from '../../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { TelMask, mtel } from '../../../../vendor/mascaras';

export default function ModalNovoTelefone({ pessoaid, visible, change, refreshTelefones }) {

  const inputNumero = useRef(null);
  const [loaderPage, setloaderPage] = useState(false);
  
  const validationSchema = yup.object({
    numero: yup
      .string('Insira o número')
      .required('Número Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      principal: false,
      whatsapp: false,
      numero: '',
      obs: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
    
      await api.post('addTelefonePesssoa', {
        "pessoaId": pessoaid,
        "principal": values.principal,
        "whatsapp": values.whatsapp,
        "numero": values.numero,
        "obs": values.obs ? values.obs : null
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshTelefones(pessoaid);
      change(false);
      
    }
  });



  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo telefone
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >


          <Grid item xs={6} md={4} >
            <FormGroup>
                <FormControlLabel control={
                    <Switch
                        id="principal"
                        name="principal"
                        checked={formik.values.principal}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                } label="Principal" />
            </FormGroup>
          </Grid>

          <Grid item xs={6} md={4} >
            <FormGroup>
                <FormControlLabel control={
                    <Switch
                        id="whatsapp"
                        name="whatsapp"
                        checked={formik.values.whatsapp}
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                } label="WhatsApp" />
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={12} >
            <TextField
              inputRef={inputNumero}
              fullWidth
              id="numero"
              name="numero"
              label="Número"
              margin="normal"
              type="text"
              value={formik.values.numero}
              onChange={formik.handleChange}
              error={formik.touched.numero && Boolean(formik.errors.numero)}
              helperText={formik.touched.numero && formik.errors.numero}
              onKeyUp={()=>{ formik.setFieldValue('numero', mtel(formik.values.numero)) }}
              inputProps={{
                maxLength: 15,
              }}
            />
          </Grid>

          

          <Grid item xs={12} md={12} >
            <TextField
              fullWidth
              id="obs"
              name="obs"
              label="OBS"
              margin="normal"
              type="text"
              multiline
              rows={5}
              value={formik.values.obs}
              onChange={formik.handleChange}
              error={formik.touched.obs && Boolean(formik.errors.obs)}
              helperText={formik.touched.obs && formik.errors.obs}
            />
          </Grid>

          
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}