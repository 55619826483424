import React, { useEffect } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

function Fancybox(props) {
  const delegate = props.delegate || "[data-fancybox]";

  useEffect(() => {
    const opts = { 
        l10n: {
            CLOSE: "Fechar",
            NEXT: "Próximo",
            PREV: "Anterior",
            MODAL: "Você pode fechar a modal com a tecla ESC",
            ERROR: "Algo deu errado, tente novamente mais tarde",
            IMAGE_ERROR: "Imagem não encontrada",
            ELEMENT_NOT_FOUND: "Elemento HTML não encontrado",
            AJAX_NOT_FOUND: "Erro ao carregar AJAX: Não encontrado",
            AJAX_FORBIDDEN: "Erro ao carregar AJAX: Proibido",
            IFRAME_ERROR: "Erro ao carregar a página",
            TOGGLE_ZOOM: "Alternar o nível de zoom",
            TOGGLE_THUMBS: "Alternar miniaturas",
            TOGGLE_SLIDESHOW: "Alternar apresentação de slides",
            TOGGLE_FULLSCREEN: "Alternar o modo de tela cheia",
            DOWNLOAD: "Download",
          }
    };

 
    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, []);

  return <>{props.children}</>;
}

export default Fancybox;