import React, {useState, useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import api from '../../services/api';

export default function MultSelectPermissaoUsers({ title, tipoPermissao, dados, pessoaid, refreshPermissoes, disabled = false }) {

  const permissoes = [
      'Visualizar',
      'Adicionar',
      'Editar',
      'Deletar'
  ];

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [personName, setPersonName] = useState([]);

  useEffect(async()=>{ 

    let permissoesCheck = [];
    
    let infos;
    dados ? infos = Object.entries(dados) : infos = [];

    for await (let [key, value] of infos) {
        value.view && permissoesCheck.push('Visualizar');
        value.insert && permissoesCheck.push('Adicionar');
        value.update && permissoesCheck.push('Editar');
        value.delete && permissoesCheck.push('Deletar');   
    }
    
    setPersonName(permissoesCheck);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dados]);


  const handleChange = async(event, value, reason, details)=>{

    let permissoes = [];

    //PESQUISA INDEX DENTRO DE UM ARRAY
    let findview = await value.find(x => x === 'Visualizar');
    let findinsert = await value.find(x => x === 'Adicionar');
    let findupdate = await value.find(x => x === 'Editar');
    let finddelete = await value.find(x => x === 'Deletar');

    //POSICIONANDO ELEMENTOS COM NOMES E VALORES CERTOS 
    await findview   ? permissoes.push({"view": true}) : permissoes.push({"view": false})
    await findinsert ? permissoes.push({"insert": true}) : permissoes.push({"insert": false})
    await findupdate ? permissoes.push({"update": true}) : permissoes.push({"update": false})
    await finddelete ? permissoes.push({"delete": true}) : permissoes.push({"delete": false})

    //JUNTANDO ELEMENTOS NO FORMATO CERTO
    let p1 = permissoes[0];
    let p2 = permissoes[1];
    let p3 = permissoes[2];
    let p4 = permissoes[3];

    let permissoesjson = Object.assign(p1, p2, p3, p4);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.post('updatePermissoesUsuario', {
      "pessoaId": pessoaid,
      "tipoPermissao": tipoPermissao,
      "permissoes": permissoesjson
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    refreshPermissoes();

  }


  return (
    <div>
      <Autocomplete
        multiple
        id={title}
        options={permissoes}
        disabled={disabled}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        value={personName}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={handleChange}
        disableClearable={true}
        openText="Abrir Opções"
        noOptionsText="Nenhuma opção encontrada."
        renderOption={(props, option, { selected }) => (
            <li {...props}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
            />
            {option}
            </li>
        )}
        style={{ width: '100%' }}
        sx={{
            '& .MuiChip-root': {
                backgroundColor: '#001A3E',
                color: '#fff',
                padding: '10px'
            },
            '& .MuiSvgIcon-root': {
                color: '#fff!important',
            },
            '& .MuiSvgIcon-root:hover': {
                color: '#c4c4c4!important',
            },
            
        }}
        renderInput={(params) => (
            <TextField {...params} label={title} />
        )}
      />

    </div>
  );
}
