import React, {useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config

export default function DocumentPDFpessoas({ listagemPessoasExport }) {

  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        }
  });

  
  return (
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>

        <View style={styles.header}>
            <Text>Cadastro de pessoas</Text>
        </View>


        <View style={ styles.rowHeader }>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Nome</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>E-mail</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Documento</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Nascimento</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Telefones</Text>
            </View>
        </View>

        {listagemPessoasExport.map((v, k) =>{
            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.nome}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.email}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.tipo === 'Física' ? v.cpf : v.cnpj}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.nascimento}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.telefonesString}</Text>
                    </View>
                </View>
            )
        })}
        
        </Page>
    </Document> 
  );
}